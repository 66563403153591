











































import {
  MUT_NOTIFICATION,
  MUT_SHOW_NOTIFICATION,
} from "@/store/mutations/types";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

const icons = {
  success: "mdi-check-circle-outline",
  error: "mdi-cancel",
  info: "mdi-information-outline",
};

export default Vue.extend({
  name: "crm-notification",
  data() {
    return {
      view: false,
    };
  },
  computed: {
    ...mapState(["notification"]),
    timeout() {
      if (this.notification.type == "error") {
        return 60000;
      }
      return 1000;
    },
    icon(): string {
      let icon = "";

      switch (this.notification.type) {
        case "success":
          icon = icons.success;
          break;
        case "error":
          icon = icons.error;
          break;
        case "info":
          icon = icons.info;
          break;
      }

      return icon;
    },
    color(): string {
      let color = "";

      switch (this.notification.type) {
        case "success":
          color = "success";
          break;
        case "error":
          color = "error";
          break;
        case "info":
          color = "info";
          break;
      }

      return color;
    },
  },
  destroyed() {
    this.view = false;
  },
  methods: {
    ...mapMutations([MUT_NOTIFICATION, MUT_SHOW_NOTIFICATION]),
    viewDetails() {
      this.view = !this.view;
    },

    hideNotification(): void {
      this.mutShowNotification(false);
    },
    onHide(value: boolean): void {
      this[MUT_SHOW_NOTIFICATION](value);
    },
  },
});
