/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { Nomenclator } from "@/models/NomenclatorEnum";
import { Subtypes } from "@/models/Subtypes";

export async function actListProcedireSubtypes(
  { commit }: any,
  type: string
): Promise<any> {
  commit("mutLoading", true);
  try {
    if (type == "" || type == undefined) {
      const responseDataTreatment = (
        await getAPI("/sprocedures/listNom/Treatment")
      ).data;
      const responseDataSurgery = (await getAPI("/sprocedures/listNom/Surgery"))
        .data;

      const surgerySubtypes = responseDataSurgery.map((procedure: any) => ({
        ...procedure,
        type: "Surgery",
      }));
      const treatmentSubtypes = responseDataTreatment.map((procedure: any) => ({
        ...procedure,
        type: "Treatment",
      }));

      commit("mutNomencators", [...surgerySubtypes, ...treatmentSubtypes]);
      commit("mutLoading", false);

      return;
    }
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }

  const responseData = (await getAPI("/sprocedures/listNom/" + type)).data;
  const responseSubTypes = responseData.map((response: any) => ({
    ...response,
    type,
  }));

  commit("mutNomencators", responseSubTypes);
  commit("mutLoading", false);
}

export async function actCreateSubtypeNomenclator(
  { dispatch }: any,
  body: {
    name: string;
    type: string;
  }
): Promise<any> {
  await getAPI.post("/sprocedures/createNom", body);
}

export async function actRemoveNomenclator(
  { dispatch, commit }: any,
  {
    id,
    type,
  }: {
    id: number;
    type: Nomenclator;
  }
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.delete(`/sprocedures/deleteNom/${id}/${type}`);
  dispatch("actListNomenclator", type);
  commit("mutLoading", true);
}
export async function actUpdateNomenclator(
  { dispatch, commit }: any,
  value: Subtypes
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.put(`/sprocedures/updateNom`, value);
  dispatch("actListNomenclator", value.type);
  commit("mutLoading", true);
}

export async function actListNomenclator(
  { commit }: any,
  type: Nomenclator
): Promise<any> {
  commit("mutLoading", true);
  try {
    const responseData = (await getAPI(`/sprocedures/listNom/${type}`)).data;
    commit("mutNomencators", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actListReferences({ commit }: any): Promise<any> {
  try {
    commit("mutLoadingRefer", true);
    const refer = (await getAPI(`/sprocedures/listNom/HowKnow`)).data;
    commit("mutReferences", refer);
    commit("mutLoadingRefer", false);
  } catch (error: any) {
    commit("mutLoadingRefer", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actListAllNomenclator({ commit }: any): Promise<any> {
  commit("mutLoading", true);
  try {
    const treat = (await getAPI(`/sprocedures/listNom/Treatment`)).data;
    const surg = (await getAPI(`/sprocedures/listNom/Surgery`)).data;
    const access = (await getAPI(`/sprocedures/listNom/Accessory`)).data;
    const refer = (await getAPI(`/sprocedures/listNom/HowKnow`)).data;
    const motiv = (await getAPI(`/sprocedures/listNom/ReasonOff`)).data;

    commit("mutMotives", motiv);
    commit("mutTreatments", treat);
    commit("mutSurgerys", surg);
    commit("mutAccessories", access);
    commit("mutReferences", refer);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actListNomCanceles(
  { commit }: any,
  type: string
): Promise<any> {
  commit("mutLoading", true);
  try {
    if (type == "" || type == undefined) {
      const responseDataTreatment = (
        await getAPI("/sprocedures/listNomNoActive/Treatment")
      ).data;
      const responseDataSurgery = (
        await getAPI("/sprocedures/listNomNoActive/Surgery")
      ).data;

      const surgerySubtypes = responseDataSurgery.map((procedure: any) => ({
        ...procedure,
        type: "Surgery",
      }));
      const treatmentSubtypes = responseDataTreatment.map((procedure: any) => ({
        ...procedure,
        type: "Treatment",
      }));

      commit("mutNomencators", [...surgerySubtypes, ...treatmentSubtypes]);
      commit("mutLoading", false);

      return;
    }
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }

  const responseData = (await getAPI("/sprocedures/listNomNoActive/" + type))
    .data;
  const responseSubTypes = responseData.map((response: any) => ({
    ...response,
    type,
  }));

  commit("mutNomencators", responseSubTypes);
  commit("mutLoading", false);
}
