import { RouteConfig } from "vue-router";

const SMSRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "SMS main",
    component: () => import("@/views/SMS/SMS.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/sms",
    name: "SMS3CX main",
    component: () => import("@/views/SMS/SMS3CX.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/admin-sms",
    name: "Admin Sms",
    component: () => import("@/views/SMS/admin/AdminSMS.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default SMSRoutes;
