import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const ConfigurationsRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Configurations",
    component: () => import("@/views/Configurations/Configurations.vue"),
    meta: {
      permissionsRequired: [Permission.LIST_CLIENT],
      requiresAdmin: true,
    },
  },
  {
    path: "/leads_center",
    name: "Leads",
    component: () => import("@/views/Configurations/LeadsCenter.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/financial",
    name: "Financial",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-financial" */ "@/views/Configurations/Financial.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/sales",
    name: "Sales",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-sales" */ "@/views/Configurations/Sales.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/payments-generals",
    name: "PaymentsGenerals",
    component: () =>
      import(
        /* webpackChunkName: "paymentsgenerals" */ "@/views/Configurations/PaymentsGenerals.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/quotes-general",
    name: "QuotesGeneral",
    component: () =>
      import(
        /* webpackChunkName: "quotesgeneral" */ "@/views/Configurations/QuotesGeneral.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/sell-accessories",
    name: "Sell-Accessories",
    component: () =>
      import(
        /* webpackChunkName: "sellaccessories" */ "@/views/Configurations/SellAccessories.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/clock-in-check",
    name: "Clockin-Check",
    component: () =>
      import(
        /* webpackChunkName: "clockin-check" */ "@/views/Configurations/ClockIn-Control.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "@/views/UserProfile.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/contacts",
    name: "User Contacts",
    component: () =>
      import(
        /* webpackChunkName: "User Contacts" */ "@/views/UserContacts.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/logs",
    name: "Logs",
    component: () =>
      import(/* webpackChunkName: "Logs" */ "@/views/Configurations/Logs.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/logs/log/:uuid",
    name: "log_view",
    component: () =>
      import(
        /* webpackChunkName: "Logs" */ "@/views/Configurations/ViewLogs.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
];

export default ConfigurationsRoutes;
