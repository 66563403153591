/* eslint-disable */
import { axiosBase, getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { isDevelop } from "@/enviorment";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import { Settings } from "@/models/Settings";
import router from "@/router";
import {
  MUT_USEDOCUSIGN,
  MUT_CONFIG,
  MUT_CONFIG_ID,
  MUT_DATA_COLORS,
  MUT_DATA_MENU,
  MUT_UPDATE_LOCAL_STORAGE,
  MUT_UPDATE_PROFILE,
  MUT_UPDATE_SIDEBAR_SELECTED,
} from "../mutations/types";
import store from "@/store";
import { Link } from "@/models/Link";
import i18n from "@/i18n";
import { Themes } from "@/models/Colors";
// USER ACTIONS
// run the below action to get a new access token on expiration
interface SHOWHIDE {
  item: Link;
  value: boolean;
}

export async function refreshToken({ state, commit }: any) {
  const refreshUrl = "/users/refresh";
  try {
    await getAPI
      .post(refreshUrl, { refresh_token: state.refreshToken })
      .then((res) => {
        if (res.status === 201) {
          commit("setAccessToken", res.data.access_token);
        }
      });
  } catch (error) {
    console.log(error, "error");
  }
}

const addsettingUrl = "/setting/create";
const updatesettingUrl = "/setting/update/";
const deletesettingUrl = "/setting/delete/";
const settingUrl = "/setting/list";
const settingPublic = "/setting/listPublic";
export async function actAddSettings(
  { commit, dispatch }: any,
  value: Settings
) {
  try {
    await getAPI.post(addsettingUrl, value);
    dispatch("actSettings");
  } catch (error) {
    notificationError();
  }
}

export async function actDeleteSettings(
  { commit, dispatch }: any,
  uuid: string
) {
  try {
    await getAPI.delete(deletesettingUrl + uuid);
    dispatch("actSettings");
  } catch (error) {
    notificationError();
  }
}
export async function actUpdateSettings(
  { commit, dispatch }: any,
  setting: Settings
) {
  try {
    await getAPI.put(updatesettingUrl + setting.uuid, {
      name: setting.name,
      value: setting.value,
      description: setting.description,
      active: setting.active,
      isPublic: setting.isPublic,
    });
    dispatch("actSettings");
    dispatch("actGeneralConfig");
  } catch (error) {
    notificationError();
  }
}
export async function actSettings({ commit, dispatch }: any) {
  const ResponseData = (await getAPI(settingUrl)).data;
  const ResponseOutConfig = ResponseData.filter(
    (res: Settings) => res.name != "general_config"
  );
  commit("mutSettings", ResponseOutConfig);
  dispatch("actSettingsOrganice", ResponseData);
}

export function actSettingsOrganice({ commit }: any, settings: Settings[]) {
  settings.forEach((sts) => {
    switch (sts.name) {
      case "title":
        break;
      case "bgcolor":
        commit("mutDataCRMBGColor", sts.value);
        break;
      case "VUE_APP_BASE_URL":
        if (sts.active) {
          commit("mutBaseURL", sts.value);
        }
        break;
      default:
        break;
    }
  });
}

export function actSaveConfig() {
  console.log("asd");
}

// export function registerUser(_context: any, data: any): Promise<any> {
//   return new Promise((resolve, reject) => {
//     axiosBase
//       .post("/register", {
//         name: data.name,
//         email: data.email,
//         username: data.username,
//         password: data.password,
//         confirm: data.confirm,
//       })
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function logoutUser(context: any): void {
  if (context.getters.loggedIn) {
    getAPI("/users/logout")
      .then(() => {
        context.commit("updateactivesms", false);
        context.commit("destroyToken");
        clearInterval((window as any).sheduleMessageRequest);
        router.push({ name: "auth" });
      })
      .catch(() => {
        //
      });
  }
}

export function forceLogout({ commit }: any) {
  commit("updateactivesms", false);
  commit("destroyToken");
  router.push({ name: "auth" });
}

export async function loginUser(
  { commit }: any,
  credentials: { email: string; password: string }
): Promise<any> {
  const response = await axiosBase.post("/users/employee/login", {
    email: credentials.email,
    password: credentials.password,
  });
  const responseData = response.data;

  if (response.status === ResponseStatus.OK) {
    commit(MUT_UPDATE_LOCAL_STORAGE, {
      access: responseData.access_token,
      refresh: responseData.refresh_token,
    });

    commit(MUT_UPDATE_PROFILE, responseData.user);
  } else {
    // UNEXPECTED ERROR
    isDevelop && console.log(response);
  }
}
export async function impersonateUser(
  { commit }: any,
  data: any
): Promise<any> {
  const response = await getAPI.post("/users/employee/impersonate", data);
  const responseData = response.data;
  if (response.status === ResponseStatus.OK) {
    commit(MUT_UPDATE_LOCAL_STORAGE, {
      access: responseData.access_token,
      refresh: responseData.access_token,
    });
    commit(MUT_UPDATE_PROFILE, responseData.user);
  } else {
    // UNEXPECTED ERROR
    isDevelop && console.log(response);
  }
}
// END USER ACTIONS

// SIDEBAR SELECT MENU
export function setSidebarSelected(context: any, item: string): void {
  context.commit(MUT_UPDATE_SIDEBAR_SELECTED, item);
}
// END SIDEBAR SELECT MENU

export async function actGeneralConfig({ commit }: any) {
  const ColorsDefault: Themes = {
    light: {
      primary: "#00bcd5",
      hprimary: "#141414",
      secondary: "#424242",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#ffc107",
    },
  };

  const SideBar: Link[] = [
    {
      hide: false,
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      href: "/dashboard/",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
    },
    {
      hide: false,
      title: i18n.t("appointments"),
      icon: "mdi-calendar-account",
      href: "/appointments/",
      showIf:
        store.getters.isDoctor ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS, DOCTORS",
    },
    {
      hide: false,
      title: i18n.t("labelEmployees"),
      icon: "mdi-account-outline",
      href: "/employees",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("companies"),
      icon: "mdi-briefcase-variant-outline",
      href: "/companies",
      showIf: store.getters.isSuper,
      showFor: "ONLY SUPER",
    },
    {
      hide: false,
      title: store.getters.getpatientN + "s",
      icon: "mdi-medical-bag",
      href: "/patients",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
    },
    {
      hide: false,
      title: i18n.t("labelSMS"),
      icon: "mdi-chat-processing",
      href: "/chat",
      showIf: true,
      showFor: "ALL",
      method: "twilio",
    },
    {
      hide: false,
      title: "Admin SMS",
      icon: "mdi-chat-alert",
      href: "/admin-sms",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
      method: "twilio",
    },
    {
      hide: false,
      title: i18n.t("labelCallCenter"),
      icon: "mdi-phone",
      href: "/call-center",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
      method: "twilio",
    },
    {
      hide: true,
      title: i18n.t("labelSMS"),
      icon: "mdi-chat-processing",
      href: "/sms",
      showIf: true,
      showFor: "ALL",
      method: "3cx",
    },
    {
      hide: true,
      title: i18n.t("labelCallCenter"),
      icon: "mdi-phone",
      href: "/calls",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
      method: "3cx",
    },
    {
      hide: false,
      title: i18n.t("leadscenter"),
      icon: "mdi-headset",
      href: "/leads_center",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
    },
    {
      hide: false,
      title: i18n.t("payments"),
      icon: "mdi-cash-usd",
      href: "/payments-generals",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("quotes"),
      icon: "mdi-clipboard-text",
      href: "/quotes-general",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("sellaccessory"),
      icon: "mdi-cash-usd",
      href: "/sell-accessories",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
    },
    {
      hide: false,
      title: i18n.t("financial"),
      icon: "mdi-finance",
      href: "/financial",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("sales"),
      icon: "mdi-currency-usd",
      href: "/sales",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },

    {
      hide: false,
      title: i18n.t("clockincheck"),
      icon: "mdi-clock",
      href: "/clock-in-check",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("nomenclators"),
      icon: "mdi-cogs",
      href: "/nomenclators",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("config"),
      icon: "mdi-bell",
      href: "/config",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: "Logs",
      icon: "mdi-clipboard-alert",
      href: "/logs",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
  ];

  try {
    const resData = (await getAPI(settingPublic)).data;
    const docunSign = resData.filter(
      (data: Settings) => data.name == "UseDocusign"
    )[0];
    const responseData = resData.filter(
      (data: Settings) => data.name == "general_config"
    )[0];
    const com = resData.filter(
      (data: Settings) => data.name == "COMPANY_NAME"
    )[0];
    if (com != undefined && com != null) {
      commit("mutDataCompany", com.value);
    }

    if (docunSign != undefined && docunSign != null) {
      commit(MUT_USEDOCUSIGN, docunSign.active);
    } else {
      commit(MUT_USEDOCUSIGN, false);
    }

    commit(MUT_CONFIG_ID, responseData.uuid);
    if (responseData != undefined && responseData != null) {
      let dataCRM = JSON.parse(responseData.value);

      if (dataCRM.methodCall == undefined) {
        dataCRM = { ...dataCRM, methodCall: "twilio" };
      }
      if (dataCRM.requireNotes == undefined) {
        dataCRM = { ...dataCRM, requireNotes: false };
      }
      if (dataCRM.sellerN == undefined) {
        dataCRM = { ...dataCRM, sellerN: "Seller" };
      }
      if (dataCRM.patientN == undefined) {
        dataCRM = { ...dataCRM, patientN: "Patient" };
      }
      if (dataCRM.viewPayments == undefined) {
        dataCRM = { ...dataCRM, viewPayments: false };
      }
      if (dataCRM.coordinatorN == undefined) {
        dataCRM = { ...dataCRM, coordinatorN: "Surgical coordinator" };
      }
      if (dataCRM.appointmetsTypes == undefined) {
        const apty = [
          {
            label: "Treatment",
            value: "TREATMENT",
          },
          {
            label: "Surgery",
            value: "SURGERY",
          },
          {
            label: "Evaluation",
            value: "EVALUATION",
          },
          {
            label: "Follow Up",
            value: "FOLLOWUP",
          },
        ];
        dataCRM = { ...dataCRM, appointmetsTypes: apty };
      }

      dataCRM.menu.map((m: Link) => {
        if (m.hide == undefined) {
          m.hide = false;
        }
      });

      delete dataCRM.notTwilio;

      dataCRM.menu.forEach((element: Link) => {
        SideBar.forEach((el: Link) => {
          if (element.href == el.href) {
            el.hide = element.hide;
            if (el.method != undefined) {
              if (el.method == dataCRM.methodCall) {
                el.hide = false;
              } else {
                el.hide = true;
              }
            }
          }
          if (el.href == "/patients") {
            el.title = dataCRM.patientN + "s";
          }
        });
      });

      commit(MUT_CONFIG, dataCRM);
      commit(MUT_DATA_MENU, SideBar);
    } else {
      commit(MUT_DATA_MENU, SideBar);
      commit(MUT_DATA_COLORS, ColorsDefault);
    }
  } catch (error) {
    commit(MUT_DATA_MENU, SideBar);
    commit(MUT_DATA_COLORS, ColorsDefault);
  }
}

//REFRESH COLORS
export function actRefreshColors({ commit }: any) {
  const ColorsDefault: Themes = {
    light: {
      primary: "#00bcd5",
      hprimary: "#141414",
      secondary: "#424242",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#ffc107",
    },
  };

  commit(MUT_DATA_COLORS, ColorsDefault);
}

//Config Hide SIDEMENU
//otro
export async function changeStatus(context: any, status: string): Promise<any> {
  try {
    await getAPI("/users/changeMyStatus/" + status);
  } catch (error) {
    notificationError();
  }
}

///Clock in and out
export async function actClockin({ commit }: any, req: any) {
  try {
    const responseData = (await getAPI.put("/clocking/changeStatus", req)).data;
    notifySuccess(`Status was changed`);
    return responseData;
  } catch (error) {
    notificationError();
  }
}

//User Contacts
//List
export async function actGetContacts({ commit }: any) {
  try {
    commit("updateloadingContact", true);
    const response = (await getAPI("/contacts")).data;
    commit("updateUserContacts", response);
    commit("updateloadingContact", false);
  } catch (error) {
    // notificationError();
  }
}
//Create
export async function actCreateContacts({ commit }: any, req: any) {
  try {
    commit("updateloadingContact", true);
    await getAPI.post("/contacts", req);
    notifySuccess("Contact has been added");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
//Update
export async function actUpdateContacts({ commit }: any, req: any) {
  try {
    const uuid = req.uuid;
    const body = req;

    delete body.uuid;
    commit("updateloadingContact", true);
    await getAPI.put("/contacts/" + uuid, body);
    notifyInfo("Contact has been updated");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actDeleteContacts({ commit }: any, req: any) {
  try {
    commit("updateloadingContact", true);
    await getAPI.delete("/contacts/" + req);
    notifyInfo("Contact has been deleted");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
